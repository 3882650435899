.selfie-verification {
    .selfie-border {
        background:
            linear-gradient(to right, var(--color-custom-primary-400) 4px, transparent 4px) 0 0,
            linear-gradient(to right, var(--color-custom-primary-400) 4px, transparent 4px) 0 100%,
            linear-gradient(to left, var(--color-custom-primary-400) 4px, transparent 4px) 100% 0,
            linear-gradient(to left, var(--color-custom-primary-400) 4px, transparent 4px) 100% 100%,
            linear-gradient(to bottom, var(--color-custom-primary-400) 4px, transparent 4px) 0 0,
            linear-gradient(to bottom, var(--color-custom-primary-400) 4px, transparent 4px) 100% 0,
            linear-gradient(to top, var(--color-custom-primary-400) 4px, transparent 4px) 0 100%,
            linear-gradient(to top, var(--color-custom-primary-400) 4px, transparent 4px) 100% 100%;

        background-repeat: no-repeat;
        background-size: 22px 22px;
    }

    .selfie-border-container {

        .left-area,
        .right-area {
            width: 25px;
            background-color: #f8f8f8;
        }

        .top-area,
        .bottom-area {
            height: 25px;
            background-color: #f8f8f8;
            margin-left: 25px;
        }

        .selfie-border {
            margin-left: 25px;
            margin-top: 25px;
        }
    }

    @media only screen and (max-width: 639px) {
        .camera-preview {
            height: 400px;
            width: 300px;
        }

        .selfie-border-container {

            .left-area,
            .right-area {
                height: 400px;
            }

            .top-area,
            .bottom-area {
                width: 250px;

            }
        }

        .selfie-border {
            height: 350px;
            width: 250px;
        }
    }

    @media only screen and (min-width: 640px) {
        .camera-preview {
            height: 500px;
            width: 400px;
        }

        .selfie-border-container {

            .left-area,
            .right-area {
                height: 500px;
            }

            .top-area,
            .bottom-area {
                width: 350px;
            }
        }

        .selfie-border {
            height: 450px;
            width: 350px;
        }
    }

}