.form {
    -webkit-mask: url("/images/form.svg") no-repeat 50% 50%;
    mask: url("/images/form.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.id {
    -webkit-mask: url("/images/id.svg") no-repeat 50% 50%;
    mask: url("/images/id.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.selfie {
    -webkit-mask: url("/images/selfie.svg") no-repeat 50% 50%;
    mask: url("/images/selfie.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.linkBank {
    -webkit-mask: url("/images/linkBank.svg") no-repeat 50% 50%;
    mask: url("/images/linkBank.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.person {
    -webkit-mask: url("/images/person.svg") no-repeat 50% 50%;
    mask: url("/images/person.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.personal {
    -webkit-mask: url("/images/personal.svg") no-repeat 50% 50%;
    mask: url("/images/personal.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.passport {
    -webkit-mask: url("/images/passport.svg") no-repeat 50% 50%;
    mask: url("/images/passport.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.mykad {
    -webkit-mask: url("/images/mykad.svg") no-repeat 50% 50%;
    mask: url("/images/mykad.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.image {
    -webkit-mask: url("/images/image.svg") no-repeat 50% 50%;
    mask: url("/images/image.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.verification {
    -webkit-mask: url("/images/verification.svg") no-repeat 50% 50%;
    mask: url("/images/verification.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.office {
    -webkit-mask: url("/images/office.svg") no-repeat 50% 50%;
    mask: url("/images/office.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.uploadIcon {
    -webkit-mask: url("/images/uploadIcon.svg") no-repeat 50% 50%;
    mask: url("/images/uploadIcon.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.ssm {
    -webkit-mask: url("/images/ssm.svg") no-repeat 50% 50%;
    mask: url("/images/ssm.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.emailIcon {
    -webkit-mask: url("/images/light/mail.svg") no-repeat 50% 50%;
    mask: url("/images/light/mail.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.circle {
    -webkit-mask: url("/images/circle.svg") no-repeat 50% 50%;
    mask: url("/images/circle.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
}
