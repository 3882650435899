.signin {

    .signin-icon {
        &::part(native) {
            background-size: 27px;
            background-repeat: no-repeat;
            background-position: 15px 5px;
        }
    }

    .signin-google {
        &::part(native) {
            background-image: url("/images/light/google.svg");
        }
    }

    .signin-mail {
        &::part(native) {
            background-image: url("/images/light/mail.svg");
            background-position: 15px 10px;
        }
    }

    .signin-phone {
        &::part(native) {
            background-image: url("/images/light/phone.svg");
            background-position-y: 3px;
        }
    }

    .signin-flag {
        background-image: url("/images/light/malaysia.svg");
        background-position: 0 3px;
        background-size: 23px;
    }
}

.dark-theme {
    .signin {
        .signin-flag {
            background-image: url("/images/dark/malaysia.svg");
        }

        .signin-phone {
            &::part(native) {
                background-image: url("/images/dark/phone.svg");
            }
        }

        .signin-mail {
            &::part(native) {
                background-image: url("/images/dark/mail.svg");
            }
        }

        .signin-google {
            &::part(native) {
                background-image: url("/images/dark/google.svg");
            }
        }
    }
}

@media only screen and (max-width: 576px) {}