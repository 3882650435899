@tailwind base;
@tailwind components;
@tailwind utilities;

/**import variable scss**/

@import "./fontFamily.scss";



body {
  margin: 0;
  font-family: Metropolis !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: Metropolis !important;
    font-size: 16px;
    font-weight: normal !important;
  }

  h1 {
    font-size: 38px !important;
    @extend .font-bold;
  }

  h2 {
    font-size: 22px !important;
    @extend .font-bold;
  }

  h3 {
    font-size: 16px !important;
    @extend .font-bold;
  }

  h4 {
    font-size: 12px !important;
    @extend .font-bold;
  }

  h5 {
    font-size: 10px !important;
    @extend .font-bold;
  }

  h1 {
    font-size: 38px !important;
  }

  ion-popover {
      --width: auto;
  }

}

/* src/styles/tailwind.css */

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-scale-in {
  animation: scaleIn 0.3s ease-out;
}

// tailwind theme variables

.theme-dark {
  --color-custom-primary-100: #1b77f7; //primary
  --color-custom-secondary-100: #000000; //table
  --color-custom-secondary-90: #08182a; //navbar
  --color-custom-secondary-75: #4d4d4d; //title, subtitle, label, textform field label, cards
  --color-custom-secondary-50: #a5a5a5; //placeholder
  --color-custom-secondary-25: #f1f1f1; //Screen background
  --color-custom-secondary-00: #ffffff; //background , card && textformfield Background, button
  --color-custom-pending: #fdcb3b; //icon
  --color-custom-success: #4bb443; //text
  --color-custom-failure: #f03f3f; //text, button
  --color-custom-mousehover-50: #e8f2fe;
  //ionic overwrites
  --ion-color-primary: var(--color-custom-primary-100);
  --ion-color-primary-shade: var(--color-custom-primary-100);
  --ion-color-primary-tint: var(--color-custom-primary-100);
  --ion-color-secondary: var(--color-custom-secondary-75);
  --ion-color-primary-shade: var(--color-custom-secondary-75);
  --ion-color-primary-tint: var(--color-custom-secondary-75);
}

.theme-light {
  --color-custom-primary-100: #1b77f7; //primary
  --color-custom-secondary-100: #000000; //table
  --color-custom-secondary-90: #08182a; //navbar
  --color-custom-secondary-75: #4d4d4d; //title, subtitle, label, textform field label, cards
  --color-custom-secondary-50: #a5a5a5; //placeholder
  --color-custom-secondary-25: #f1f1f1; //Screen background
  --color-custom-secondary-00: #ffffff; //background , card && textformfield Background, button  #e8f2fe
  --color-custom-pending: #fdcb3b; //icon
  --color-custom-success: #4bb443; //text
  --color-custom-failure: #f03f3f; //text, button
  --color-custom-mousehover-50: #e8f2fe;
  //ionic overwrites
  --ion-color-primary: var(--color-custom-primary-100);
  --ion-color-primary-shade: var(--color-custom-primary-100);
  --ion-color-primary-tint: var(--color-custom-primary-100);
  --ion-color-secondary: var(--color-custom-secondary-75);
  --ion-color-primary-shade: var(--color-custom-secondary-75);
  --ion-color-primary-tint: var(--color-custom-secondary-75);
}

.signin-background {
  background-image: linear-gradient(to right, var(--color-custom-primary-400), var(--color-custom-primary-300));
}

.centerbox{
  display: block;
  margin: 25px auto;
}
.continue{
  display: block;
  margin: 0 auto;
  position: sticky;
  bottom: 0;
  z-index: 10;
}
.continue-sticky{
  position: fixed;
  bottom: 30px;
  z-index: 10;
  left: 0;
  width: 4rem;
}

.col-span-23 {
  grid-column: span 23;
}

.header-sticky{
  position: sticky;
  top: 0;
  z-index: 10;
}
.custom-acc-div{
  width: 600px
}
.custom-button {
  width: 325px;
  
}
.font-size-lg{
  font-size: 22px;
}

.full-page-height{
  min-height: 80vh;
}
.sticky-footer{
  position: sticky;
  top: 100;
 
}
.small-button {
  width: 150px; /* Adjust the width as desired */
  height: 30px; /* Adjust the height as desired */
  margin: 0 auto; /* Center the button horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}	
.dark-blue{
  background: #121927;
  --background: #112945;
}
.height-bluenew, .height-blue{
  height: 100%;
}
.width-of-screen {
  width: calc(100vw - 15px);
}
.admin-bar {
  position: fixed;
  top: 0;
  right: -300px; /* Change the value to control the width of the bar */
  height: 100vh;
  width: 300px; /* Change the value to control the width of the bar */
  background-color: #11bdfb;
  transition: right 0.3s ease-in-out;
  z-index: 11;
  }

  .button-size {
    
    font-size: 16px !important;
    height: 36px !important;
  }

  .primary-color{
    --background: #1b77f7;
  }

  
.resizewindow{
  width: calc(100% - 300px);
}

.custom-ion-item {
  margin: 0 !important;
  padding: 0 !important;
}

.leading-tight {
   line-height: 0.25;
}

.custom-input:focus {
  outline: none !important;
}





@media only screen and (max-width: 500px) {
  .continue-sticky{
    position: fixed;
    bottom: 30px;
    z-index: 10;
    left: 25%;
    width: 10rem;
  }
  .centerbox{
      display:block;
      margin:1em;
 }
  .continue{
      display: block;
      margin: 0 auto;
      width: 50%;
 }

 .common-item{
 --padding-start: 0;
}



}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}