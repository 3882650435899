/**font family**/
@font-face {
    font-family: 'Metropolis';
    src: url('./fonts/Metropolis-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis-Bold';
    src: url('./fonts/Metropolis-Bold.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis-medium';
    src: url('./fonts/Metropolis-Medium.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis-light';
    src: url('./fonts/Metropolis-Thin.otf');
    font-weight: normal;
    font-style: normal;
}

.font-normal {
    font-weight: medium !important;
    font-family: Metropolis-Medium !important;
}

.font-bold {
    font-weight: bold !important;
    font-family: Metropolis-Bold !important;
}

.font-light {
    font-weight: lighter !important;
    font-family: Metropolis-light !important;
}