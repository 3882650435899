/* Core CSS required for Ionic components to work properly */
@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';


/**Ionic Styles overwrite**/

@import "./fontFamily.scss";
@import "./icons.scss";



.ion-page {
  overflow: auto;
  color: var(--color-custom-secondary-75);

  //button overwrite
  ion-button {
   // height: 42px;

    &::part(native) {
      --border-radius: 5px !important;
      font-size: 14px;
      height: 36px;
      @extend .font-normal;
    }

    &[fill="block"] {
      background: var(--color-custom-primary-100);

      &::part(native) {
        color: var(--color-custom-secondary-25);
      }
    }

    &[fill="outline"] {
      border-color: var(--color-custom-primary-100) !important;

      &::part(native) {
        color: var(--color-custom-secondary-75) !important;
      }
    }

    &.button-disabled {
      &::part(native) {
        background-color: var(--color-custom-primary-100) !important;
        color: var(--color-custom-secondary-75) !important;
      }
    }

    &.ion-activated {
      &::part(native) {
        &::after {
          background-color: var(--color-custom-primary-100) !important;
          color: var(--color-custom-secondary-75) !important;
        }
      }
    }
  }

  //text box overwrite
  .app-input-filed {
    //border-color: var(--color-custom-primary-100) !important;
    color: var(--color-custom-secondary-75) !important;
   // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);

    &::part(native) {
      background: transparent;
      border-width: var(--border-width);
      border-style: var(--border-style);
      border-color: var(--color-custom-primary-100);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      color: var(--ion-color-light);
      border: 1px solid;
      border-radius: 5px;

    }

    &.item-has-focus::part(native) {
      border: 2px solid !important;
      border-width: var(--border-width);
      border-style: var(--border-style);
      border-color: var(--color-custom-primary-100) !important;
    }    

    &.app-input-has-error {
      border-color: var(--color-custom-failure) !important;

      &::part(native) {
        border-color: var(--color-custom-failure) !important;
      }
    }
  }

  //dropdown overwrite
  ion-select {
    border: 1px solid;
    border-radius: 5px !important;
  }

  ion-item {
    &::part(native) {
      background: transparent;
    }
  }

  ion-button{ 
    --box-shadow:none;
   }

   .common-item{
    --color-hover: none;
    --background-hover:none;
    --background-focused:none;
    --highlight-color-focused:none;
    --background-activated-opacity:none;
    --background-hover-opacity:none;
    --highlight-color-focused: none;
    --background-activated: transparent;
    --ripple-color: transparent;

  }

  

  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px var(--color-custom-secondary-00) inset;
    border: 0px;
    -webkit-text-fill-color: var(--color-custom-secondary-75) !important;
    border-radius: 0.325rem;
  }

  &.theme-dark {
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  .rp-alert {
    .alert-message.sc-ion-alert-md {
      font-size: 22px !important;
      @extend .font-normal;
    }

  }
}


ion-item{
  // height: 39px !important;
  border-radius: 8px;
  }
  .ion-page .app-input-filed::part(native){
  min-height: 36px !important;
  border-radius: 8px;
  border-color: secondary-50;
  }
  ion-input{
  height: 31px !important;
  
  }
  .ion-page ion-select{
    height: 36px !important;
    border-radius: 8px !important;
    border-color: secondary-50;
  }

  .select-icon {
    width: 28px !important;
  }


  .ion-page ion-button.button-disabled::part(native)
  // .ion-page ion-button[fill="outline"]::part(native)
    {
    color: #ffffff !important;
}
.ion-page .app-input-filed::part(native){
  height: 36px !important;
}

// .native-input.sc-ion-input-md:-webkit-autofill{
//   padding-left: 0px !important;
//   padding-right: 0px !important;
// }


.spinner-container img {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
  margin: auto !important;
  
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75); /* Blue background with 50% opacity */
  z-index: 9999; /* Adjust the z-index based on your layout */
}



/* Desktop */
@media (min-width: 768px) {
  .spinner-container img {
    width: 12%;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .spinner-container img {
    width: 36%;
  }
}

@media (min-width: 600px) {
  .min-width-600 {
    width: 50%; 
   }
}

@media (max-width: 600px) {
  .max-width-600 {
    width: 100%; 
   }
}



/**Ionic Styles overwrite**/